import React, {useRef, Fragment} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {useAsyncSetState} from "use-async-setstate";
import {useQuery} from "@apollo/client";
import {Link} from "gatsby";

import Purchase from "../components/section/purchase";
import ProductSelector from "../components/bootstrap/product-selector";
import SEO from "../components/section/seo";
import Main from "../components/main";
import {
  checkServiceAvailabilityQuery,
  getBuildingCIS,
} from "../components/logic/address";
import {setFormData} from "../components/manager/form";
import root from "window-or-global";
import LogoMonoImage from "../images/vostro-logo-pack/vostronet-logo-cmyk.svg";

export default function Index(props) {
  return (
    <Main>
      <DataLayer {...props} />
    </Main>
  );
}

function DataLayer(props) {
  const params = new URLSearchParams(root.location?.search);
  const unitNumber = params.get("unitno");
  const buildingCode = params.get("building");

  const query = useQuery(checkServiceAvailabilityQuery, {
    variables: {
      buildingCode,
      unitNo: unitNumber,
    },
  });

  if (query.loading) {
    return <Fragment />;
  }

  const building =
    query?.data?.classMethods?.Building?.checkServiceAvailability;

  return <Register building={building} {...props} />;
}

function Register({building}) {
  const params = new URLSearchParams(root.location?.search);
  const unitNumber = params.get("unitno");
  const buildingCode = params.get("building");
  const myRef = useRef(null);
  const [selectedProduct, setProduct] = useAsyncSetState(null);
  const [step, setStep] = useAsyncSetState({
    addressSelector: false,
    productSelector: true,
    purchase: false,
  });
  const [userData, setUserData] = useAsyncSetState(null);
  const [selectedProducts, setSelectedProducts] = useAsyncSetState([]);
  const [planIndex, setPlanIndex] = useAsyncSetState(0);

  const query = useQuery(getBuildingCIS, {
    variables: {
      code: buildingCode,
    },
    errorPolicy: "all",
  });

  if (query.loading) {
    return <Fragment />;
  }

  if (query.error) {
    if (query.error.graphQLErrors.length > 0) {
      return (
        <section className="text-center pt-10 error-section-container">
          <div className="font-weight-bold pb-1 error-section-header">
            Oops! An error occurred
          </div>
          <section className="pb-5">
            {query.error.graphQLErrors[0].message}
          </section>
          <Link to="/" className="error-section-button">
            Back to Home
          </Link>
        </section>
      );
    }
  }

  const cisLink = query?.data?.classMethods?.Building?.getBuildingCIS || "https://vost.ro/vcis";

  if (building) {
    setFormData({
      building: {address: building, code: buildingCode},
      unit: unitNumber,
      serviceAvailability: building,
    });
  } else {
    return (
      <section className="text-center pt-10 error-section-container">
        <div className="font-weight-bold pb-1 error-section-header">
          Oops! Something went wrong
        </div>
        <section className="pb-5">No service available</section>
        <Link to="/join-us" className="error-section-button">
          Go Back
        </Link>
      </section>
    );
  }

  const handleSelectProduct = async (product) => {
    await setStep({
      productSelector: false,
      addressSelector: false,
      purchase: true,
    });
    return setProduct(product);
  };

  const handleOnSuccessPurchase = async () => {
    return setStep({
      productSelector: false,
      addressSelector: true,
      purchase: false,
    });
  }

  const handleReturnToProductSelector = async () => {
    return setStep({
      productSelector: true,
      addressSelector: false,
      purchase: false,
    });
  }

  return (
    <Fragment>
      <SEO title="VostroNet - Internet Provider">
        <meta name="description" content="VostroNet - Register" />
      </SEO>
      <div className="plan-section register" ref={myRef}>
        <Container>
          <Row>
            <Col xs={"auto"} className="mx-auto logo">
              <a tabIndex="-1" href="/">
                <img src={LogoMonoImage} />
              </a>
            </Col>
          </Row>
        </Container>
        {step.productSelector && (
          <>
            <ProductSelector
              cisLink={cisLink}
              buildingCode={buildingCode || ""}
              onSelectProduct={(product) => handleSelectProduct(product)}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              index={planIndex}
              setIndex={setPlanIndex}
            />
          </>
        )}

        {step.purchase && (
          <Container className="mx-auto">
            <Purchase
              product={selectedProduct}
              selectProduct={(data) => setProduct(data)}
              onSuccess={() => handleOnSuccessPurchase()}
              userData={userData}
              setUserData={setUserData}
              handleReturnToProductSelector={handleReturnToProductSelector}
            />
          </Container>
        )}
      </div>
    </Fragment>
  );
}
